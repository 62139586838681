<template>
  <div class="all-container">
    <div class="app-container">
      <div class="app-content">
        <conversationList></conversationList>
        <chatArea></chatArea>
        <userInfo></userInfo>
      </div>
    </div>
  </div>
</template>

<script>
import conversationList from "@/components/qiscus/ConversationList.vue";
import chatArea from "@/components/qiscus/ChatArea.vue";
import userInfo from "@/components/qiscus/UserInfo.vue";
export default {
  components: {
    conversationList,
    chatArea,
    userInfo
  }
};
</script>

<style>
@import "../assets/css/qiscus/menu.css";
@import "../assets/css/qiscus/main.css";
@import "../assets/css/qiscus/micromodal.css";
@import "../assets/css/qiscus/modal.css";
@import "../assets/css/qiscus/qiscus-sdk.2.10.0.css";
@import "../assets/css/qiscus/inbox.css";
@import "../assets/css/qiscus/flex.css";
@import "../assets/css/qiscus/override.css";
@import "../assets/css/qiscus/simplemde.min.css";
@import "../assets/css/qiscus/rw-chat-base.css";
.all-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
</style>
